import { config as firebaseQa } from "./qa.firebaseConfig";
import { config as firebaseProduction } from "./production.firebaseConfig";

/* eslint no-useless-escape: 0 */
const previewRegExp =
  /([^ ]*taxscribe-gov-qa-ipp-app--pr[0-9]{1,3}\-.+?\.\web\.app)/;

const detectStage = () => {
  const { hostname } = window.location;

  if (hostname === "ipp.gov.taxscribe.app") {
    return "production";
  }

  if (hostname === "qa-ipp.gov.taxscribe.app") {
    return "qa";
  }

  if (hostname.match(previewRegExp)) {
    return "preview";
  }

  return "development";
};

const configOpts = {
  production: {
    firebaseConfig: firebaseProduction,
    baseUri: "https://ipp.gov.taxscribe.app",
    clientUri: "https://ipp.gov.taxscribe.app",
  },
  qa: {
    firebaseConfig: firebaseQa,
    baseUri: "https://qa-ipp.gov.taxscribe.app",
    clientUri: "https://qa-ipp.gov.taxscribe.app",
  },
  preview: {
    firebaseConfig: firebaseQa,
  },
  development: {
    firebaseConfig: firebaseQa,
    baseUri: "http://localhost:3000",
    clientUri: "http://localhost:3000",
  },
};

const releaseStage = detectStage();
const devMode = ["qa", "preview", "development"].indexOf(releaseStage) >= 0;
const config = { ...configOpts[releaseStage], releaseStage, devMode };

export default config;
