import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import icon from "../images/view-file-icon.svg";

export default function Listings({ results }) {
  return (
    <>
      <Card>
        {results.map((result, idx) => {
          const {
            ownershipInfo_accountNumber,
            ownershipInfo_firstName,
            ownershipInfo_lastName,
            physicalLocation_address,
            physicalLocation_address2,
            physicalLocation_city,
            physicalLocation_jurisState,
            physicalLocation_zipCode,
            reviewStatus,
            storageKey,
            year,
          } = result;
          return (
            <ListGroup key={idx} variant="flush" className="border-top-0">
              <ListGroup.Item>
                <Row className="p-3 d-flex justify-content-between align-items-center listing-row">
                  <Col xl={9} lg={6}>
                    <Row>
                      <Col
                        xl={2}
                        className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
                      >
                        <p className="fw-bold mb-xl-0">
                          {ownershipInfo_accountNumber}
                        </p>
                      </Col>
                      <Col
                        xl={3}
                        className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
                      >
                        <h6 className="mb-lg-0 text-uppercase fw-normal">
                          {ownershipInfo_firstName} {ownershipInfo_lastName}
                        </h6>
                      </Col>
                      <Col
                        xl={7}
                        className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start"
                      >
                        <p className="text-uppercase fw-light mb-lg-0">
                          <small>
                            {physicalLocation_address}{" "}
                            {physicalLocation_address2}
                            <br />
                            {`${physicalLocation_city},
                          ${physicalLocation_jurisState}`}{" "}
                            {physicalLocation_zipCode}
                          </small>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={3} lg={6}>
                    <Row>
                      <Col
                        lg={3}
                        className="d-flex justify-content-lg-end align-self-center flex-column flex-xl-row text-center text-xl-end align-items-center "
                      >
                        <h6 className="mb-lg-0">{year}</h6>
                      </Col>
                      <Col
                        lg={7}
                        className="d-flex justify-content-lg-center align-items-center flex-column flex-xl-row text-center"
                      >
                        <h6
                          className={`${reviewStatus} text-uppercase mb-lg-0 py-3 px-4`}
                        >
                          {reviewStatus === "rejected"
                            ? "returned"
                            : reviewStatus}
                        </h6>
                      </Col>
                      <Col
                        lg={2}
                        className="d-flex justify-content-lg-center align-items-center flex-column flex-xl-row text-center"
                      >
                        <Link to={`ipp/${reviewStatus}/${storageKey}`}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip-2" className="mb-2">
                                View Listing
                              </Tooltip>
                            }
                          >
                            <span>
                              <img
                                alt="view-listing-logo"
                                src={icon}
                                width="26"
                                height="32"
                              />{" "}
                            </span>
                          </OverlayTrigger>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          );
        })}
      </Card>
    </>
  );
}
