import { useEffect } from "react";
import { useAuth } from "./useAuth.js";
import { useNavigate } from "react-router-dom";

export function useRequireAuth() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = auth;
  // const emailVerified = user && user.emailVerified;

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (user === false) {
      return navigate("/login");
    }

    // if (emailVerified === false) {
    //   return navigate("/verify");
    // }
  }, [user, navigate]);

  return auth;
}
