import { Col, Row } from "react-bootstrap";

const getFormDate = (formDate) => {
  const seconds = formDate._seconds;
  const nanoseconds = formDate._nanoseconds;
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  return date.toJSON();
};

export default function SubmissionForm({ formData }) {
  return (
    <>
      <div className="p-4 bg-light">
        <Row className="mb-3">
          <Col xs={9}>
            <h2 className="h4">Ownership Information</h2>
          </Col>
        </Row>

        <Row className="justify-content-between" id="owner-information">
          <Col md={4} lg={2}>
            <p className="fw-bold text-nowrap">
              Name
              <br />
              <span className="fw-normal">
                {formData.ownershipInfo.firstName}
              </span>
              &nbsp;
              <span className="fw-normal">
                {formData.ownershipInfo.lastName}
              </span>
            </p>
          </Col>
          <Col md={4} lg={2}>
            <p className="fw-bold text-nowrap">
              Account Number
              <br />
              <span className="fw-normal">
                {formData.ownershipInfo.accountNumber}
              </span>
            </p>
          </Col>
          <Col md={4} lg={2}>
            <p className="fw-bold">
              Email
              <br />
              <span className="fw-normal text-nowrap">
                {formData.ownershipInfo.email}
              </span>
            </p>
          </Col>
          <Col md={4} lg={2}>
            <p className="fw-bold text-nowrap">
              Phone Number
              <br />
              <span className="fw-normal">
                {formData.ownershipInfo.phoneNumber}
              </span>
            </p>
          </Col>
        </Row>
        <div className="clearfix d-none d-print-block"></div>
        <Row>
          <Col lg={4}>
            <p className="fw-bold mb-0 text-nowrap">
              Address
              <br />
              <span className="fw-normal">
                {formData.ownershipInfo.address}
              </span>
              &#44;&nbsp;
              <span className="fw-normal">
                {formData.ownershipInfo.address2}
              </span>
              <br />
              <span className="fw-normal">{formData.ownershipInfo.city}</span>
              &#44;&nbsp;
              <span className="fw-normal">
                {formData.ownershipInfo.jurisState}
              </span>
              &nbsp;
              <span className="fw-normal">
                {formData.ownershipInfo.zipCode}
              </span>
            </p>
          </Col>
        </Row>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Property Location</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-nowrap">
                <span>{formData.physicalLocation.address}</span>
                &#44;&nbsp;
                <span>{formData.physicalLocation.address2}</span>
                <br />
                <span>{formData.physicalLocation.city}</span>
                &#44;&nbsp;
                <span>{formData.physicalLocation.jurisState}</span>
                &nbsp;
                <span>{formData.physicalLocation.zipCode}</span>
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Aircraft</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.aircraft.map((craft, idx) => {
              return (
                <Col lg={4} key={idx} className="ipp-print-col">
                  <div className="mb-4">
                    <b>Model Year: </b>
                    <span>{craft.modelYear}</span>
                    <br />
                    <b>Body: </b>
                    <span>{craft.body}</span>
                    <br />
                    <b>Make: </b>
                    <span>{craft.make}</span>
                    <br />
                    <b>Model Year: </b>
                    <span>{craft.modelYear}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{craft.yearAcquired}</span>
                    <br />
                    <b>Acquistion Cost: </b>
                    <span>{craft.acquisitionCost}</span>
                    <br />
                    <b>Property Id: </b>
                    <span>{craft.propertyId}</span>
                    <br />
                    <b>Airport: </b>
                    <span>{craft.airport}</span>
                    <br />
                    <b>Hanger Number: </b>
                    <span>{craft.hangerNumber}</span>
                    <br />
                    <b>Tail Number: </b>
                    <span>{craft.tailNumber}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Vehicle - Unregistered</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.unregisteredVehicles.map((item, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className="mb-4">
                    <b>Model Year: </b>
                    <span>{item.modelYear}</span>
                    <br />
                    <b>Make: </b>
                    <span>{item.make}</span>
                    <br />
                    <b>Model: </b>
                    <span>{item.model}</span>
                    <br />
                    <b>Body: </b>
                    <span>{item.body}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{item.yearAcquired}</span>
                    <br />
                    <b>Acquisition Cost: </b>
                    <span>{item.acquisitionCost}</span>
                    <br />
                    <b>Property ID: </b>
                    <span>{item.propertyId}</span>
                    <br />
                    <b>Length: </b>
                    <span>{item.length}</span>
                    <br />
                    <b>Width: </b>
                    <span>{item.width}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Vehicle - International Registration Plate</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.internationalPlateVehicles.map((item, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className="mb-4">
                    <b>Model Year: </b>
                    <span>{item.modelYear}</span>
                    <br />
                    <b>Make: </b>
                    <span>{item.make}</span>
                    <br />
                    <b>Model: </b>
                    <span>{item.model}</span>
                    <br />
                    <b>Body: </b>
                    <span>{item.body}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{item.yearAcquired}</span>
                    <br />
                    <b>Acquisition Cost: </b>
                    <span>{item.acquisitionCost}</span>
                    <br />
                    <b>Property Id: </b>
                    <span>{item.propertyId}</span>
                    <br />
                    <b>Length: </b>
                    <span>{item.length}</span>
                    <br />
                    <b>Width: </b>
                    <span>{item.width}</span>
                    <br />
                    <b>Title Number: </b>
                    <span>{item.titleNumber}</span>
                    <br />
                    <b>License Plate Number: </b>
                    <span>{item.licensePlateNumber}</span>
                    <br />
                    <b>Registration Issue Date: </b>
                    <span>{item.registrationIssueDate}</span>
                    <br />
                    <b>Lessee Name: </b>
                    <span>{item.licensePlateNumber}</span>
                    <br />
                    <b>Lessee Address: </b>
                    <br />
                    <span>{item.lesseeAddress1}</span>
                    &#44;&nbsp;
                    <span>{item.lesseeAddress2}</span>
                    <br />
                    <span>{item.lesseeCity}</span>
                    &#44;&nbsp;
                    <span>{item.lesseeState}</span>
                    &nbsp;
                    <span>{item.lesseeZip}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Vehicle - Permanent Plate</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.permanentPlateVehicles.map((item, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className="mb-4">
                    <b>Model Year: </b>
                    <span>{item.modelYear}</span>
                    <br />
                    <b>Make: </b>
                    <span>{item.make}</span>
                    <br />
                    <b>Model: </b>
                    <span>{item.model}</span>
                    <br />
                    <b>Body: </b>
                    <span>{item.body}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{item.yearAcquired}</span>
                    <br />
                    <b>Acquisition Cost: </b>
                    <span>{item.acquisitionCost}</span>
                    <br />
                    <b>Property ID: </b>
                    <span>{item.propertyId}</span>
                    <br />
                    <b>Length: </b>
                    <span>{item.length}</span>
                    <br />
                    <b>Width: </b>
                    <span>{item.width}</span>
                    <br />
                    <b>Title Number: </b>
                    <span>{item.titleNumber}</span>
                    <br />
                    <b>License Plate Number: </b>
                    <span>{item.licensePlateNumber}</span>
                    <br />
                    <b>Registration Issue Date: </b>
                    <span>{item.registrationIssueDate}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Manufactured Home</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.manufacturedHomes.map((item, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className="mb-4">
                    <b>Parcel ID: </b>
                    <span>{item.parcelId}</span>
                    <br />
                    <b>Model Year: </b>
                    <span>{item.modelYear}</span>
                    <br />
                    <b>Make: </b>
                    <span>{item.make}</span>
                    <br />
                    <b>Model: </b>
                    <span>{item.model}</span>
                    <br />
                    <b>Body: </b>
                    <span>{item.body}</span>
                    <br />
                    <b>Width: </b>
                    <span>{item.width}</span>
                    <br />
                    <b>Length: </b>
                    <span>{item.length}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{item.yearAcquired}</span>
                    <br />
                    <b>Acquisition Cost: </b>
                    <span>{item.acquisitionCost}</span>
                    <br />
                    <b>VIN: </b>
                    <span>{item.vin}</span>
                    <br />
                    <b>Serial Number: </b>
                    <span>{item.serialNumber}</span>
                    <br />
                    <b>Plate Number: </b>
                    <span>{item.plateNumber}</span>
                    <br />
                    <b>Property ID: </b>
                    <span>{item.propertyId}</span>
                    <br />
                    <b>Manufactured Home Park: </b>
                    <span>{item.manufacturedHomePark}</span>
                    <br />
                    <b>Lot Number: </b>
                    <span>{item.lotNumber}</span>
                    <br />
                    <b>Title Number: </b>
                    <span>{item.titleNumber}</span>
                    <br />
                    <b>Occupant: </b>
                    <span>{item.occupant}</span>
                    <br />
                    <b>Title Name 1: </b>
                    <span>{item.titleName1}</span>
                    <br />
                    <b>Title Name 2: </b>
                    <span>{item.titleName2}</span>
                    <br />
                    <b>Moving Permit Number: </b>
                    <span>{item.movingPermitNumber}</span>
                    <br />
                    <b>Moving Permit Issue Date: </b>
                    <span>{item.movingPermitIssueDate}</span>
                    <br />
                    <b>Building Permit Number: </b>
                    <span>{item.buildingPermitNumber}</span>
                    <br />
                    <b>Building Permit Issue Date: </b>
                    <span>{item.buildingPermitIssueDate}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Watercraft</h2>
            </Col>
          </Row>
          <Row className="review-print-schedule-d">
            {formData.watercraft.map((item, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className="mb-4">
                    <b>Model Year: </b>
                    <span>{item.modelYear}</span>
                    <br />
                    <b>Make: </b>
                    <span>{item.make}</span>
                    <br />
                    <b>Model: </b>
                    <span>{item.model}</span>
                    <br />
                    <b>Length in Feet: </b>
                    <span>{item.lengthFeet}</span>
                    <br />
                    <b>Length in Inches: </b>
                    <span>{item.lengthInches}</span>
                    <br />
                    <b>Fuel Code: </b>
                    <span>{item.fuelCode}</span>
                    <br />
                    <b>Model Code: </b>
                    <span>{item.modelCode}</span>
                    <br />
                    <b>Propulsion Code: </b>
                    <span>{item.propulsionCode}</span>
                    <br />
                    <b>Primary Use Code: </b>
                    <span>{item.primaryUseCode}</span>
                    <br />
                    <b>Year Acquired: </b>
                    <span>{item.yearAcquired}</span>
                    <br />
                    <b>Acquisition Cost: </b>
                    <span>{item.acquisitionCost}</span>
                    <br />
                    <b>Property ID: </b>
                    <span>{item.propertyId}</span>
                    <br />
                    <b>Marina: </b>
                    <span>{item.marina}</span>
                    <br />
                    <b>Slip Number: </b>
                    <span>{item.slipNumber}</span>
                    <br />
                    <b>Slip Number Extension: </b>
                    <span>{item.slipNumberExtension}</span>
                    <br />
                    <b>Motor Make 1: </b>
                    <span>{item.motorMake1}</span>
                    <br />
                    <b>Motor Make 2: </b>
                    <span>{item.motorMake2}</span>
                    <br />
                    <b>Motor HP 1: </b>
                    <span>{item.motorHP1}</span>
                    <br />
                    <b>Motor HP 2: </b>
                    <span>{item.motorHP2}</span>
                    <br />
                    <b>Motor Serial Number 1: </b>
                    <span>{item.motorSerialNumber1}</span>
                    <br />
                    <b>Motor Serial Number 2: </b>
                    <span>{item.motorSerialNumber2}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <hr />
      <div>
        <div className="p-4 bg-light ">
          <Row>
            <Col xs={9} className="mb-3">
              <h2 className="h4">Signature</h2>
            </Col>
          </Row>
          <Row
            className="justify-content-between align-items-end"
            id="signature"
          >
            <Col md="auto" lg={2}>
              <p className="border-bottom border-secondary mb-0">
                <span>
                  {formData.affirmation.firstName}{" "}
                  {formData.affirmation.lastName}
                </span>
              </p>
              <p>
                <span className="fw-bold text-muted">
                  <small>Name</small>
                </span>
              </p>
            </Col>
            <Col md="auto" lg={3}>
              <p className="border-bottom border-secondary mb-0">
                <span>{formData.affirmation.email}</span>
              </p>
              <p>
                <span className="fw-bold text-muted">
                  <small>Email</small>
                </span>
              </p>
            </Col>
            <Col md="auto" lg={3}>
              <p className="border-bottom border-secondary mb-0">
                <span>{formData.affirmation.emailConfirmation}</span>
              </p>
              <p>
                <span className="fw-bold text-muted">
                  <small>Confirmation Email</small>
                </span>
              </p>
            </Col>
            <Col md="auto" lg={2}>
              <p className="border-bottom border-secondary mb-0">
                <span>{formData.affirmation.phoneNumber}</span>
              </p>
              <p>
                <span className="fw-bold text-muted">
                  <small>Phone Number</small>
                </span>
              </p>
            </Col>
            <Col md="auto" lg={2}>
              <p className="border-bottom border-secondary mb-0">
                <span>
                  {formData.confirmedAt &&
                    new Date(
                      getFormDate(formData.confirmedAt)
                    ).toLocaleDateString()}
                </span>
              </p>
              <p>
                <span className="fw-bold text-muted">
                  <small>Date</small>
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
