import { useParams } from "react-router-dom";
import { functions } from "../config/firebaseInit";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useRequireAuth } from "../hooks/useRequireAuth";
import {
  Col,
  Container,
  Navbar,
  Row,
  Spinner,
  Nav,
  Button,
} from "react-bootstrap";
import Sidebar from "./shared/Sidebar";
import SubmissionForm from "./SubmissionForm";

const readFormData = httpsCallable(functions, "readFormData");
const decisionForm = httpsCallable(functions, "decisionForm");

const IsLoading = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center mt-5">
        <Spinner
          animation="border"
          variant="secondary"
          role="status"
          style={{ height: "5rem", width: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  );
};

const DecisionLabel = ({ status }) => {
  return (
    <Nav className="flex-row">
      <h6 className={`${status} text-uppercase mb-lg-0 py-3 px-4`}>{status}</h6>
    </Nav>
  );
};

const DecisionButtons = ({ isLoading, handleDecision }) => {
  return (
    <Nav className="flex-row">
      <Button
        variant="warning"
        className="me-3 px-sm-5 px-4 fs-5"
        disabled={isLoading}
        onClick={handleDecision("reject")}
      >
        Return
      </Button>{" "}
      <Button
        variant="success"
        className="px-sm-5 px-4 fs-5"
        disabled={isLoading}
        onClick={handleDecision("approve")}
      >
        Approve
      </Button>
    </Nav>
  );
};

const defaultData = {
  confirmedAt: {},
  year: "",
  createdAt: {},
  createdByUid: "",
  submittedAt: {},
  ownershipInfo: {},
  physicalLocation: {},
  affirmation: {},
  aircraft: [],
  unregisteredVehicles: [],
  internationalPlateVehicles: [],
  permanentPlateVehicles: [],
  manufacturedHomes: [],
  watercraft: [],
};

export default function ReviewForm() {
  const { user } = useRequireAuth();
  const { status, gcpStorageKey } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [decisionStatus, setDecisionStatus] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        const response = await readFormData({
          gcpStorageKey,
          status,
        });

        const {
          data: {
            formData: { submissionData },
          },
        } = response;
        setFormData({ ...defaultData, ...submissionData });
        setDecisionStatus(status);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    })();
  }, [user, status, gcpStorageKey]);

  const handleDecision = (decision) => {
    return async (e) => {
      const decisionLabel = e.target.textContent;

      const confirmation = window.confirm(
        `Are you sure you want to ${decisionLabel.toUpperCase()} this form?`
      );

      if (!confirmation) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await decisionForm({
          status: decision,
          gcpStorageKey,
        });

        const {
          data: { message },
        } = response;

        setDecisionStatus(message);
        setIsLoading(false);
      } catch (err) {
        console.warn("Decisioning error", err);
        setDecisionStatus(status);
        setIsLoading(false);
      }
    };
  };

  return (
    <>
      <div className="layout layout-nav-side">
        <Sidebar />
        <main className="w-100">
          <Navbar id="header-nav" expand="lg" className="bg-white sticky-top">
            <Container fluid className="border-bottom px-0 py-3 mx-3">
              <Navbar.Brand className="text-uppercase ls-1">
                IPP Form
              </Navbar.Brand>
              {decisionStatus === "submitted" ? (
                <DecisionButtons
                  isLoading={isLoading}
                  handleDecision={handleDecision}
                />
              ) : (
                <DecisionLabel status={decisionStatus} />
              )}
            </Container>
          </Navbar>
          <Container fluid id="main-content" className="pt-2 pb-3">
            {isLoading ? <IsLoading /> : <SubmissionForm formData={formData} />}
          </Container>
        </main>
      </div>
    </>
  );
}
