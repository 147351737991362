import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import logo from "../../images/rc-logo-gov.png";
import { firestore as db } from "../../config/firebaseInit";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [signInEmail, setSignInEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const user = await auth.signin(signInEmail, password);
      const { uid, email } = user;

      const timestamp = serverTimestamp();

      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await setDoc(
          docRef,
          { userId: uid, lastSignedInAt: timestamp },
          { merge: true }
        );
        navigate("/");
      } else {
        const userData = {
          email,
          userId: uid,
          createdAt: timestamp,
          lastSignedInAt: timestamp,
        };

        await setDoc(docRef, { ...userData });

        navigate("/");
      }
    } catch (err) {
      setError(
        "The email or password you entered did not match our records. Please double-check and try again."
      );
      console.log(err);
    }
  }

  useEffect(() => {
    if (auth.user) {
      navigate("/");
    }
  }, [auth.user, navigate]);

  return (
    <main>
      <Container fluid className="ps-md-0">
        <Row className="g-0">
          <Col
            md={4}
            className="d-none d-md-flex justify-content-center login-bg grey-background"
          >
            <div className="d-flex align-items-center text-center">
              <div>
                <img alt="reason-logo" src={logo} width="250" height="auto" />{" "}
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="login d-flex align-items-center py-5">
              <Container>
                <Row>
                  <Col md={8} lg={6} className="mx-auto">
                    <h1 className="login-heading mb-4 h2 fw-light">
                      TaxScribe Government
                    </h1>
                    <form
                      id="form-signin-box"
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email Address"
                          onChange={(e) => setSignInEmail(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {error ? (
                        <div
                          role="alert"
                          className="fade alert border-0 rounded-0 text-white show alert-message-danger"
                        >
                          {error}
                        </div>
                      ) : null}
                      <Button type="submit" className="btn btn-dark w-100">
                        Submit
                      </Button>
                    </form>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
