import ListingsContainer from "./ListingsContainer";
import Sidebar from "./shared/Sidebar";
import Container from "react-bootstrap/Container";

export default function Home() {
  return (
    <>
      <div className="layout layout-nav-side">
        <Sidebar />
        <main className="w-100">
          <Container fluid id="main-content" className="py-3">
            <ListingsContainer />
          </Container>
        </main>
      </div>
    </>
  );
}
