import { Button, Navbar } from "react-bootstrap";
import logo from "../../images/rc-logo-gov.png";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";

export default function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  async function handleSignOut() {
    try {
      await auth.signout();
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <Navbar
      id="sidebar"
      className="grey-background navbar-dark sticky-sm-top px-2"
    >
      <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-1 pt-2 text-white">
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white" }}
          className="fs-4 display-6 text-uppercase ls-1"
        >
          <img
            alt="reason-logo"
            id="reason-logo"
            className="mb-3 me-2"
            src={logo}
            width="120"
            height="auto"
          />{" "}
          <span className="d-sm-block">Individual</span>
          <span className="d-sm-block">Personal</span>
          <span className="d-sm-block">Property</span>
        </Link>
        <div className="py-sm-2 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
          <Button
            id="log-out-btn"
            variant="outline-light"
            size="lg"
            className="text-nowrap text-uppercase ls-1"
            onClick={handleSignOut}
          >
            Log Out
          </Button>
        </div>
      </div>
    </Navbar>
  );
}
