import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCircleChevronUp,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";

function SortDropDownItem({
  children,
  clickedIndex,
  index,
  handleClick,
  sortAscending,
}) {
  const isClicked = clickedIndex === index;
  const className = "px-3 py-2 text-capitalize" + (isClicked ? " fw-bold" : "");
  const handleItemClick = (evt) => handleClick(evt, index);

  const icon = (
    <FontAwesomeIcon
      className="px-1"
      icon={sortAscending ? faCircleChevronUp : faCircleChevronDown}
      aria-hidden="true"
    />
  );

  return (
    <Dropdown.Item {...{ className, onClick: handleItemClick }}>
      {children}
      {isClicked && icon}
    </Dropdown.Item>
  );
}

export default function SortDropdown({
  options,
  defaultSortDirection = "asc",
  initialSortDirection = "asc",
  handleSort: handleClick,
}) {
  const initialSortBoolean = initialSortDirection === "asc";
  const defaultSortBoolean = defaultSortDirection === "asc";
  const [clickedIndex, setClickedIndex] = useState(0);
  const [sortAscending, setSortAscending] = useState(initialSortBoolean);

  const handleSortClick = (evt, idx) => {
    const nextSort = idx === clickedIndex ? !sortAscending : defaultSortBoolean;
    setSortAscending(nextSort);
    setClickedIndex(idx);
    return handleClick(evt, idx, nextSort ? "asc" : "desc");
  };

  return (
    <Dropdown>
      <strong>Sort</strong>
      <Dropdown.Toggle
        id="sort-dropdown-btn"
        variant="link"
        className="dropdown-icon"
      >
        <FontAwesomeIcon
          icon={faSort}
          size="lg"
          aria-hidden="true"
          className="text-dark"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1 ls-1 dropdown-menu-list" id="sort-dropdown">
        <Dropdown.Header className="fw-bold">Sort By</Dropdown.Header>
        {options.map((option, idx) => {
          return (
            <SortDropDownItem
              {...{
                key: idx,
                index: idx,
                clickedIndex,
                sortAscending,
                handleClick: handleSortClick,
              }}
            >
              {option}
            </SortDropDownItem>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
