import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import Listings from "./Listings";
import { useRequireAuth } from "../hooks/useRequireAuth";
import { useSearch } from "../hooks/useSearch";
import FilterDropdown from "./FilterDropdown";
import SortDropdown from "./SortDropdown";

import { firestore as db } from "../config/firebaseInit";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const IsLoading = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center mt-5">
        <Spinner
          animation="border"
          variant="secondary"
          role="status"
          style={{ height: "5rem", width: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  );
};

const NoResults = () => {
  return (
    <Row className="text-center my-4">
      <Col>
        <h2 className="ls-1 text-uppercase">No Records Found</h2>
      </Col>
    </Row>
  );
};

const Results = ({ isLoading, searchResults }) => {
  if (isLoading) {
    return <IsLoading />;
  }

  if (searchResults.length > 0) {
    return <Listings results={searchResults} />;
  }

  return <NoResults />;
};

export default function ListingsContainer() {
  const { user } = useRequireAuth();

  const { isLoading, searchQuery, searchResults, setSearchParams } =
    useSearch();
  const [userSearch, setUserSearch] = useState(searchQuery.query);
  const searchEl = useRef(null);

  useEffect(() => {
    const { current } = searchEl;

    const handleClear = (e) => {
      if (!e.target.value) {
        setSearchParams();
      }
    };

    if (current === null) {
      return;
    }

    current.addEventListener("search", handleClear);
    return () => {
      current.removeEventListener("search", handleClear);
    };
  }, [searchEl, setSearchParams]);

  const dropDownOptions = ["All", "Approved", "Returned", "Submitted"];
  const dropDownSortOptions = ["Submitted At", "City", "Zip Code"];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchParams({ query: userSearch });
  };

  const handleFilter = (e) => {
    const selectedText = e.target.text;
    const statusText = selectedText.toLowerCase();
    const status = statusText === "returned" ? "rejected" : statusText;

    setSearchParams({ status });
  };

  const handleSort = (evt, index, sortDirection) => {
    const sortCol = dropDownSortOptions[index];

    setSearchParams({ col: sortCol, order: sortDirection });
  };

  const handleExport = async (evt) => {
    const buttonText = evt.target.textContent;

    const confirmation = window.confirm(
      `Are you sure you want to ${buttonText.toUpperCase()} your current search results?`
    );

    if (!confirmation) {
      return;
    }

    const { uid } = user;
    const { status } = searchQuery;
    const timestamp = serverTimestamp();

    const exportRequest = {
      userId: uid,
      query: userSearch,
      status,
      year: "2023",
      requestedAt: timestamp,
      completedAt: null,
    };

    try {
      const exportsCollectionRef = collection(db, "exports");
      await addDoc(exportsCollectionRef, exportRequest);
      alert("Export requested!");
    } catch (err) {
      console.warn(err);
      alert("There was an issue processing your request. Please try again.");
    }
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;

    setUserSearch(value);
  };

  return (
    <>
      <Navbar id="header-nav" expand="lg" className="bg-white sticky-top">
        <Container fluid className="border-bottom px-0 py-3 mx-3">
          <Navbar.Brand className="text-uppercase ls-1">Listings</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-lg-end">
            <Form
              onSubmit={handleSearch}
              role="search"
              className="d-flex my-2 my-lg-0"
            >
              <Form.Control
                type="search"
                placeholder="Search Account Number, Name, or Address"
                className="me-2"
                aria-label="Search Account Number, Name, or Address"
                id="search-form"
                value={userSearch}
                ref={searchEl}
                onChange={handleChange}
                autoComplete="off"
              />
              <Button
                variant="dark"
                type="submit"
                className="px-sm-5 px-4 fs-5"
              >
                Search
              </Button>
            </Form>
            <Nav className="my-2 my-lg-0 ms-lg-3">
              <Button
                variant="dark"
                className="px-sm-5 px-4 fs-5"
                onClick={handleExport}
              >
                Export
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Row className="mb-1 p-0">
        <Col className="d-flex align-items-end">
          <h2 className="fs-6 text-uppercase ls-1 ms-1">All IPP Listings</h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <SortDropdown
            options={dropDownSortOptions}
            handleSort={handleSort}
            initialSortDirection="desc"
          />
          <FilterDropdown
            options={dropDownOptions}
            handleFilter={handleFilter}
          />
        </Col>
      </Row>
      {isLoading && searchResults.length > 0 ? (
        <Listings results={searchResults} />
      ) : (
        <Row>
          <Col>
            <Results {...{ searchResults, isLoading }} />
          </Col>
        </Row>
      )}
    </>
  );
}
