import { useEffect, useState } from "react";
import { functions } from "../config/firebaseInit";
import { httpsCallable } from "firebase/functions";
import { useLocalStorage } from "./useLocalStorage";

const searchSubmissions = httpsCallable(functions, "searchSubmissions");

const defaultQuery = {
  status: "all",
  query: "",
  sort: {
    col: "submittedAt",
    order: "desc",
  },
};

export function useSearch() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useLocalStorage(
    "searchQuery",
    defaultQuery
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await searchSubmissions(searchQuery);
        const {
          data: { docs },
        } = response;

        setSearchResults(docs);
        setIsLoading(false);
      } catch (err) {
        console.warn("fetch listings", err);
        setIsLoading(false);
      }
    })();
  }, [searchQuery]);

  const cleanQuery = (query) => {
    if (typeof query !== "string") {
      return searchQuery.query;
    }

    return String(query);
  };

  const setSearchParams = ({ status, query, col, order } = defaultQuery) => {
    const validStatuses = {
      all: "all",
      approved: "approved",
      rejected: "rejected",
      submitted: "submitted",
    };

    const sortOptions = {
      "Submitted At": "submittedAt",
      City: "physicalLocation_city",
      "Zip Code": "physicalLocation_zipCode",
    };

    const sortOrders = {
      asc: "asc",
      desc: "desc",
    };

    const userQuery = cleanQuery(query);
    const statusFilter = validStatuses[status] || searchQuery.status;

    const columnOption = col || "submittedAt";
    const orderOption = order || "desc";

    const sortCol =
      columnOption === "submittedAt" ? columnOption : sortOptions[columnOption];
    const sortOrder = sortOrders[orderOption];

    const queryOpts = {
      status: statusFilter,
      query: userQuery,
    };

    const sortOpts = { col: sortCol, order: sortOrder };

    setSearchQuery({ ...searchQuery, ...queryOpts, sort: sortOpts });
  };

  return { isLoading, searchQuery, searchResults, setSearchParams };
}
