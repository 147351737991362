import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export default function FilterDropdown({ options, handleFilter: handleClick }) {
  return (
    <Dropdown>
      <strong>Filter</strong>
      <Dropdown.Toggle
        id="filter-dropdown-btn"
        variant="link"
        className="dropdown-icon"
      >
        <FontAwesomeIcon
          icon={faFilter}
          size="lg"
          aria-hidden="true"
          className="text-dark"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="p-1 ls-1 dropdown-menu-list"
        id="filter-dropdown"
      >
        {options.map((option, idx) => {
          return (
            <Dropdown.Item
              key={idx}
              className="px-3 py-2 fw-bold text-capitalize"
              onClick={handleClick}
            >
              {option}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
