import Alert from "react-bootstrap/Alert";

const defaultMsg = (
  <>
    You are in a testing environment. Any actions taken <b>will not</b> be
    officially saved.
  </>
);

export default function QABanner({ message = defaultMsg }) {
  return (
    <div id="qa-banner" className="fixed-top">
      <Alert className="text-center mb-0" variant="warning">
        {message}
      </Alert>
    </div>
  );
}
