export const config = {
  apiKey: "AIzaSyBXodPuZT7bj8d3NfDkY6L-Ou0o7AqXmJU",
  authDomain: "taxscribe-gov-qa.firebaseapp.com",
  projectId: "taxscribe-gov-qa",
  storageBucket: "taxscribe-gov-qa.appspot.com",
  messagingSenderId: "852676336603",
  appId: "1:852676336603:web:9763d49283bcefcff4e5fe",
  measurementId: "G-FWTBBX2PRX"
};

